/*
 * Message提供了对SemanticUI message的简单封装.
 * http://semantic-ui.com/collections/message.html
 **/

import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTimeout from 'react-timeout';

class Message extends React.Component {
  constructor(props) {
    super(props);
    this._$el = null;
  }

  _close() {
    this._$el.transition({
      animation: 'fade',
      onComplete: () => {
        this.props.onClose();
      },
    });
  }

  componentDidMount() {
    if (this._$el) {
      if (this.props.closable) {
        this._$el.find('.close').on('click', () => {
          this._close();
        });
      }

      if (this.props.autoDismiss) {
        this.props.setTimeout(() => {
          this._close();
        }, this.props.dismissTimeout * 1000);
      }
    }
  }

  componentWillUnmount() {
    if (this._$el && this.props.closable) {
      this._$el.find('.close').off('click');
    }
  }

  render() {
    let {
      tag,
      className,
      closable,
      onClose,
      autoDismiss,
      dismissTimeout,
      dangerouslySetInnerHTML,
      children,
      style,
      ...others
    } = this.props;
    return React.createElement(
      tag,
      {
        ref: (c) => (this._$el = $(c)),
        className: classNames('ui', className, 'message'),
        style,
        dangerouslySetInnerHTML,
      },
      children,
    );
  }
}
Message.propTypes = {
  tag: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  autoDismiss: PropTypes.bool,
  dismissTimeout: PropTypes.number, // seconds
  dangerouslySetInnerHTML: PropTypes.object,
};

Message.defaultProps = {
  tag: 'div',
  closable: false,
  onClose: () => {},
  autoDismiss: false,
  dismissTimeout: 10,
};

export default ReactTimeout(Message);
