import { keys } from 'lodash';
import $ from 'jquery';
import qs from 'qs';

import getEndpoint from './get-endpoint';

const baseUrl = window.API_BASE_URL || '/api/v1/';

//
// When we run frontend on localhost python web server and connect api with different host,
// Chrome will report cross-domain error, we need to install the chrome plugin to avoid it:
// https://chrome.google.com/webstore/detail/allow-control-allow-origi/nlfbmbojpeacfghkpbjhddihlkkiljbi
//

// We use two ways to call server side api: semantic ui api behavior & ajax.
// Semantic-ui api behavior provides ui button integration, and ajax is easy to use.
// http://semantic-ui.com/behaviors/api.html

$.fn.api.settings.successTest = function(response) {
  if (response && response.success && (response.success === true || response.success.toLowerCase() === 'true')) {
    return response.success;
  }
  return false;
};

$.fn.api.settings.api = {
  login: `${baseUrl}login-check`,
  signup: `${baseUrl}get-signup-code`,
  signup2: `${baseUrl}signup`,
  'get username reminder': `${baseUrl}get-username-reminder`,
  'get temp password': `${baseUrl}get-temp-password`,
  'reset password': `${baseUrl}reset-password`,

  'dashboard uservalues': `${baseUrl}dashboard-uservalues`,
  'dashboard benchmark': `${baseUrl}dashboard-benchmark`,
  'dashboard incident': `${baseUrl}dashboard-incident`,
  'live analysis': `${baseUrl}liveAnalysis`,
  'cloud outlier detection': `${baseUrl}cloudOutlierDetection`,
  'cloud rollout check': `${baseUrl}cloudRolloutCheck`,
  'display project model': `${baseUrl}displayProjectModel`,

  'dashboard dailysummaryreport': `${baseUrl}dashboard-dailysummaryreport`,
  'insight report': `${baseUrl}insightReport`,
  'published detection': `${baseUrl}publishedDetection`,
  'post mortem': `${baseUrl}postMortem`,
  'log analysis': `${baseUrl}logAnalysis`,
  'add datadog project': `${baseUrl}add-datadog-project`,
  'add new relic project': `${baseUrl}add-newrelic-project`,
  'add log project': `${baseUrl}add-log-project`,
  'add custom project': `${baseUrl}add-custom-project`,
  'add aws project': `${baseUrl}add-amazon-project`,
  'add google project': `${baseUrl}add-google-project`,
  'remove project': `${baseUrl}remove-project`,
  'project setting': `${baseUrl}emailAlertSetting`,
  'project data': `${baseUrl}projectData`,
  'external service': `${baseUrl}service-integration`,

  'upload detection': `${baseUrl}upload-detection`,
  'upload visualization': `${baseUrl}upload-visualization`,
  'upload update': `${baseUrl}upload-update`,
  'upload training': `${baseUrl}upload-training`,
  'upload display': `${baseUrl}upload-display`,

  'service integration': `${baseUrl}service-integration`,
  'publish project': `${baseUrl}publishdataproject`,
  'publish filedetection': `${baseUrl}publishdatafile`,
};

let request = function(method, action, data, resolve, reject) {
  var formData = new FormData();
  keys(data).forEach((k) => formData.append(k, data[k]));
  let xhr;
  switch (method) {
    case 'GET':
      xhr = $.get($.fn.api.settings.api[action], data);
      break;
    case 'HEAD':
      xhr = $.get($.fn.api.settings.api[action], data);
      break;
    default:
      xhr = $.post($.fn.api.settings.api[action], data);
      break;
  }
  xhr
    .done(function(resp) {
      resolve(resp);
    })
    .fail(function(error) {
      console.log(arguments);
      console.log('Server Error', arguments);
      reject(error);
    });
};

let requestGet = function(action, data, resolve, reject) {
  return request('GET', action, data, resolve, reject);
};
let requestPost = function(action, data, resolve, reject) {
  return request('POST', action, data, resolve, reject);
};

/**
 文件分析:
 UploadVisualization,
 UploadTraining,
 UploadUpdate,
 UploadDetection,
 */
const apis = {
  postUploadDetection(credentials, cvalue, pvalue, modelType, modelName, filename) {
    // Get X-CSRF-Token in localStorage
    const token = window.localStorage.getItem('X-CSRF-Token');

    return new Promise(function(resolve, reject) {
      $.ajax({
        type: 'POST',
        url: $.fn.api.settings.api['upload detection'],
        data: $.param({ uploadFileName: filename, filename, modelName, modelType, pvalue, cvalue }),
        beforeSend: function(request) {
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.setRequestHeader('Accept', 'application/json');
          request.setRequestHeader('X-CSRF-Token', token);
        },
      })
        .done(function(resp) {
          resolve(resp);
        })
        .fail(function(error) {
          console.log(arguments);
          console.log('Server Error', arguments);
          reject(error);
        });
    });
  },

  postUploadVisualization(credentials, filename) {
    // Get X-CSRF-Token in localStorage
    const token = window.localStorage.getItem('X-CSRF-Token');

    return new Promise(function(resolve, reject) {
      $.ajax({
        type: 'POST',
        url: $.fn.api.settings.api['upload visualization'],
        data: $.param({ uploadFileName: filename, filename }),
        beforeSend: function(request) {
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.setRequestHeader('Accept', 'application/json');
          request.setRequestHeader('X-CSRF-Token', token);
        },
      })
        .done(function(resp) {
          resolve(resp);
        })
        .fail(function(error) {
          console.log(arguments);
          console.log('Server Error', arguments);
          reject(error);
        });
    });
  },

  postUploadUpdate(credentials, operation, filenameFilter, modelName, filename) {
    // Get X-CSRF-Token in localStorage
    const token = window.localStorage.getItem('X-CSRF-Token');

    return new Promise(function(resolve, reject) {
      $.ajax({
        type: 'POST',
        url: $.fn.api.settings.api['upload update'],
        data: $.param({ uploadFileName: filename, filename, operation, filenameFilter, modelName }),
        beforeSend: function(request) {
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.setRequestHeader('Accept', 'application/json');
          request.setRequestHeader('X-CSRF-Token', token);
        },
      })
        .done(function(resp) {
          resolve(resp);
        })
        .fail(function(error) {
          console.log(arguments);
          console.log('Server Error', arguments);
          reject(error);
        });
    });
  },

  postUploadTraining(credentials, filenameFilter, modelName, filename) {
    let param = filenameFilter
      ? { uploadFileName: filename, filename, filenameFilter, modelName }
      : { uploadFileName: filename, filename, modelName };

    // Get X-CSRF-Token in localStorage
    const token = window.localStorage.getItem('X-CSRF-Token');

    return new Promise(function(resolve, reject) {
      $.ajax({
        type: 'POST',
        url: $.fn.api.settings.api['upload training'],
        data: $.param(param),
        beforeSend: function(request) {
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.setRequestHeader('Accept', 'application/json');
          request.setRequestHeader('X-CSRF-Token', token);
        },
      })
        .done(function(resp) {
          resolve(resp);
        })
        .fail(function(error) {
          console.log(arguments);
          console.log('Server Error', arguments);
          reject(error);
        });
    });
  },

  postUploadDisplay(credentials, modelType, modelName, modelUserName) {
    // Get X-CSRF-Token in localStorage
    const token = window.localStorage.getItem('X-CSRF-Token');

    return new Promise(function(resolve, reject) {
      $.ajax({
        type: 'POST',
        url: $.fn.api.settings.api['upload display'],
        data: $.param({ modelName, modelType, modelUserName }),
        beforeSend: function(request) {
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          request.setRequestHeader('Accept', 'application/json');
          request.setRequestHeader('X-CSRF-Token', token);
        },
      })
        .done(function(resp) {
          resolve(resp);
        })
        .fail(function(error) {
          console.log(arguments);
          console.log('Server Error', arguments);
          reject(error);
        });
    });
  },

  getModelString(credentials) {
    const query = {};
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: `${getEndpoint('models')}?${qs.stringify(query)}`,
        beforeSend: function(request) {
          request.setRequestHeader('Accept', 'application/json');
        },
      })
        .done((resp) => resolve(resp))
        .fail((err) => {
          console.log('Server Error', arguments);
          reject(err);
        });
    });
  },
};

export default apis;
