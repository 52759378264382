import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BaseComponent } from '../base';

class Table extends BaseComponent {
  static propTypes = {
    tag: PropTypes.string,
  };

  static defaultProps = {
    tag: 'table',
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    let { tag, className, children, ...others } = this.props;
    return React.createElement(
      tag,
      {
        className: classNames('ui', className, 'table'),
        ...others,
      },
      children,
    );
  }
}

export default Table;
