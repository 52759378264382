import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/*
 * Popup
**/

const Popup = class extends React.Component {
  constructor(props) {
    super(props);

    this._$el = null;
  }

  componentDidMount() {
    if (this._$el) {
      let content = this._$el.children()[0];
      $(content).popup({
        inline: true,
        hoverable: true,
        position: this.props.position || 'right center',
      });
    }
  }

  componentWillUnmount() {
    if (this._$el) {
      let content = this._$el.children()[0];
      if (content) {
        $(content).popup('destroy');
      }
    }
  }

  render() {
    let { tag, children, position, ...others } = this.props;

    return React.createElement(
      tag,
      {
        ref: (c) => (this._$el = $(c)),
        ...others,
      },
      children,
    );
  }
};

Popup.propTypes = {
  tag: PropTypes.string,
  position: PropTypes.string,
};

Popup.defaultProps = {
  tag: 'div',
  position: 'right center',
};

export default Popup;
