/*
 * 根据Semantic-UI modal实现的React Modal组件.
 * http://semantic-ui.com/modules/modal.html
 *
 * - 使用Semantic UI的modal.js来实现的react组件.
 * - 由于semantic会将modal当Dom对象移到body下的dummer, 因而需要在unmount时
 *   删除移动的Dom元素. 当React Component Unmount时, 删除移动过的Dom对象
 *   导致异常. 为避免该异常, 需在modal外添加一个div元素.
 * - 当modal关闭时, 会触发onClose事件, 在该事件中可以删除该组件.
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import $ from 'jquery';
import classNames from 'classnames';
import { autobind } from 'core-decorators';

const Modal = class Modal extends React.Component {
  constructor(props) {
    super(props);

    this._$el = null;
    this.$dialog = null;
    this.close = this.close.bind(this);
    this.modal = null;

    this.dialogIniY = 0;
    this.state = {
      dragging: false,
    };
  }

  componentDidMount() {
    if (this._$el && !this.modal) {
      this.modal = this._$el
        .find('.ui.modal')
        .modal({
          closable: false,
          allowMultiple: true,
          inverted: this.props.dimmer === 'inverted',
          blurring: this.props.dimmer === 'blurring',
          transition: this.props.transition,
          duration: 0,
          onHidden: () => {
            this.props.onClose();
          },
          onCancel: () => {
            this.props.onCancel();
          },
          onOK: () => {
            this.props.onOK();
          },
        })
        .modal('show');
    }
  }

  componentDidUpdate(props, state) {
    if (this.state.dragging && !state.dragging) {
      window.onmousemove = this.onMouseMove;
      window.onmouseup = this.onMouseUp;
    } else if (!this.state.dragging && state.dragging) {
      window.onmousemove = null;
      window.onmouseup = null;
    }

    if (this.modalel) {
      this.modal.modal('refresh');
    }
  }

  close() {
    if (this.modal) {
      this.modal.modal('hide');
      if (this.modal[0] && this.modal[0].remove) {
        this.modal[0].remove();
      }
    }
  }

  componentWillUnmount() {
    if (this.modal) {
      this.modal.modal('hide');
      if (this.modal[0] && this.modal[0].remove) {
        this.modal[0].remove();
      }
    }
  }

  @autobind
  onMouseDown(e) {
    e = e || window.event;
    // allow all body drag
    this.topHeight = 0;
    this.mx = e.pageX; // 点击时鼠标X坐标
    this.my = e.pageY; // 点击时鼠标Y坐标
    this.dx = this.$dialog.offset().left;
    this.dy = this.$dialog.offset().top;
    const left = R.replace('px', '', this.$dialog.css('left'));
    this.dialogIniX = Number(left);
    if (this.dialogIniY === 0) {
      const marginTop = R.replace('px', '', this.$dialog.css('margin-top'));
      this.dialogIniY = Number(marginTop);
    }
    this.setState({ dragging: true });
  }
  @autobind
  onMouseUp() {
    this.setState({ dragging: false });
  }
  @autobind
  onMouseMove(e) {
    e = e || window.event;
    const x = e.pageX; // 移动时鼠标X坐标
    const y = e.pageY; // 移动时鼠标Y坐标
    if (this.state.dragging) {
      // 判断对话框能否拖动
      const moveX = this.dialogIniX + x - this.mx; // 移动后对话框新的left值
      let moveY = this.dy - this.dialogIniY + y - this.my; // 移动后对话框新的top值

      // 若移动后会超出可移动范围，则限定实际移动范围
      if (moveY + this.dialogIniY - this.topHeight < 0) {
        moveY = this.topHeight - this.dialogIniY;
      }

      // 重新设置对话框的left、top
      this.$dialog.css({ left: `${moveX}px`, top: `${moveY}px` });
    }
  }

  render() {
    const { type, size, className, transition, onClose, onCancel, onOK, closable, ...other } = this.props;
    const classes = classNames('ui modal', size, type, className);

    // 在Modal外添加一个div元素, 用于避免React Unmount时异常.
    return (
      <div ref={(c) => (this._$el = $(c))}>
        <div className={classes} {...other} ref={(c) => (this.$dialog = $(c))}>
          {closable && <i className="close icon" />}
          <div
            style={{ height: 50, width: '100%', background: 'var(--component-background)', cursor: 'move' }}
            onMouseDown={this.onMouseDown}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
};

Modal.propTypes = {
  closable: PropTypes.bool,
  type: PropTypes.oneOf(['basic']),
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive']),
  transition: PropTypes.string,
  dimmer: PropTypes.string,

  // 在onClose回调函数中, 需删除本react组件以删除创建的dom对象.
  onClose: PropTypes.func.isRequired,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
};

Modal.defaultProps = {
  closable: true,
  onOK: () => {},
  onCancel: () => {},
};

export default Modal;
